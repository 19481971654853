import { useState, useEffect } from 'react';
import { WebexMeetingsWidget } from '@webex/widgets';
import axios from 'axios';
import '@webex/widgets/dist/css/webex-widgets.css';
import './App.css';

let backendUrl = "";
let token = "";

export default function App() {
    return MyComponent();
}

function MyComponent(){
    const [data, setData] = useState(null);
    token = getQueryVariable('config');
    backendUrl = getQueryVariable('backend');

    useEffect(()=>{
        async function fetchData(){
            const data = await readConfigFromToken(token);
            setData(data);
        }

        fetchData();
    }, []);

    if (data === null) {
        return <div>Loading...</div>;
    } 
    else {
        const myControls = (inMeeting) => inMeeting 
            ? ['mute-audio', 'mute-video', 'share-screen', 'member-roster', 'settings', 'leave-meeting'] 
            : ['mute-audio', 'mute-video', 'settings', 'join-meeting'];
        
        const layout = "overlay";

        return (
            <WebexMeetingsWidget
                style={{width: "100vw", height: "100vh"}} // Substitute with any arbitrary size or use `className`
                accessToken={data.token}
                meetingDestination={data.meetingDestination}
                controls={myControls}
                layout={layout}
            />
        );
    }
}

function getQueryVariable(variable){
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    for (var i=0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable){return pair[1];}
    }

    return(false);
}

async function readConfigFromToken(token) {
    try{ 
        let response = await axios({
            url: backendUrl + `/getConfig?token=${token}`,
            method: 'post',
        })

        return response.data;

    }
    catch (err) {
        console.error(err);
    }
}